import { useState } from 'react';
import { getCoupon } from 'services/apiPayment';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import Input from 'components/Input';
import Button from 'components/Button';
import styled from 'styled-components';

const Wrapper = styled.div`
  display: flex;
  align-items: start;
  width: 100%;
  gap: 16px;
`;

export default function CouponButton({ setCoupon }) {
  const { t } = useTranslation();
  const [couponCode, setCouponCode] = useState('');
  const [error, setError] = useState(false);

  const fetchCoupon = async () => {
    setError(false);
    try {
      const coupon = await getCoupon(couponCode);
      setCoupon(coupon);
    } catch (err) {
      setError(true);
      setCoupon(null);
      console.log('coupon error: ', err);
    }
  };

  return (
    <Wrapper>
      <Input
        value={couponCode}
        onChange={(e) => setCouponCode(e.target.value)}
        error={error}
        onKeyDown={(e) => e.key === 'Enter' && fetchCoupon()}
        helperText={error ? t('coupon_error') : ''}
        placeholder={t('coupon_placeholder')}
        type="text"
        width={300}
        size="default"
        required
      />
      <Button
        fontSize={14}
        onClick={fetchCoupon}
        variant={'filled'}
        color={'primary'}
        size={'large'}
      >
        {t('apply')}
      </Button>
    </Wrapper>
  );
}

CouponButton.propTypes = {
  setCoupon: PropTypes.func.isRequired,
};
