import React, { memo, useEffect } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { colors } from 'styles/theme';
import { RiCloseLine } from 'react-icons/ri';

const Backdrop = styled.div`
  position: fixed;
  display: flex;
  align-items: center;
  justify-content: center;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.3);
  background-color: rgba(0, 0, 0, 0.3);
  z-index: ${({ zIndex }) => zIndex};
`;

const Wrapper = styled.div`
  position: relative;
  width: ${({ width }) => (typeof width === 'number' ? `${width}px` : width)};
  ${({ minHeight }) => minHeight && `min-height: ${minHeight};`}
  max-height: 80%;
  background-color: ${colors.SHADES_000};
  padding: 48px 40px 40px 40px;
  border-radius: 16px;
  overflow: auto;
`;

const CloseButton = styled(RiCloseLine)`
  position: absolute;
  top: 28px;
  right: 28px;
  font-size: 24px;
  color: ${colors.SHADES_400};
  line-height: 1;
  cursor: pointer;
`;

const Title = styled.div`
  font-size: 28px;
  font-weight: bold;
  color: ${colors.SHADES_800};
  margin-bottom: 24px;
`;

const Modal = ({
  open = false,
  width = '320px',
  zIndex = 100,
  minHeight,
  title,
  showCloseButton = true,
  onClose,
  children,
}) => {
  useEffect(() => {
    document.body.style.overflow = open ? 'hidden' : '';
  }, [open]);

  useEffect(() => () => (document.body.style.overflow = ''), []);

  return open ? (
    <Backdrop zIndex={zIndex}>
      <Wrapper width={width} minHeight={minHeight}>
        {showCloseButton && <CloseButton onClick={onClose} />}
        {title && <Title>{title}</Title>}
        {children}
      </Wrapper>
    </Backdrop>
  ) : null;
};

const propTypes = {
  open: PropTypes.bool,
  width: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  title: PropTypes.oneOfType([PropTypes.string, PropTypes.element]),
  zIndex: PropTypes.number,
  minHeight: PropTypes.number,
  onClose: PropTypes.func,
  showCloseButton: PropTypes.bool,
  children: PropTypes.node,
};

Modal.propTypes = propTypes;

export default memo(Modal);
