import { responseHandler } from 'utils';

const apiEndPoint = process.env.REACT_APP_CONTRACT_API;

export const getCoupon = (couponCode) =>
  fetch(`${apiEndPoint}/coupons/short/${couponCode}`, {
    method: 'GET',
  }).then(responseHandler);

export const getProgram = (id) =>
  fetch(`${apiEndPoint}/programs/${id}`, {
    method: 'GET',
  }).then(responseHandler);

export const getContractsId = (id) =>
  fetch(`${apiEndPoint}/contracts/${id}`, {
    method: 'GET',
  }).then((response) => {
    if (!response.ok) {
      throw new Error('Network response was not ok');
    }
    return response.json();
  });

export const submitContracts = (contractData) =>
  fetch(`${apiEndPoint}/contracts`, {
    method: 'POST',
    body: JSON.stringify(contractData),
    headers: {
      client: 'payment',
    },
  }).then(responseHandler);

export const createPurchase = ({ clientId, contractIds }) =>
  fetch(`${apiEndPoint}/purchases`, {
    method: 'POST',
    body: JSON.stringify({ clientId, contractIds }),
  }).then(responseHandler);
