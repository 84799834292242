import styled from 'styled-components';
import { Routes, Route } from 'react-router-dom';
import 'normalize.css/normalize.css';
import 'styles/global.scss';
import { colors } from 'styles/theme';
import Header from 'components/Header';
import Footer from 'components/Footer';
import PaymentForm from 'pages/PaymentForm';
import SuccessPage from 'pages/SuccessPage';

const AppWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  min-height: 100%;
  background-color: ${colors.SHADES_50};
  min-width: 100%;
  margin: 0 auto;
`;

const ContentWrapper = styled.div`
  width: 100%;
  max-width: 1184px;
  padding: 48px;
  @media (max-width: 375px) {
    padding: 36px 16px;
  }
`;

function App() {
  return (
    <AppWrapper>
      <Header />
      <ContentWrapper>
        <Routes>
          <Route path="/" element={<PaymentForm />} />
          <Route path="/success" element={<SuccessPage />} />
        </Routes>
      </ContentWrapper>
      <Footer />
    </AppWrapper>
  );
}

export default App;
