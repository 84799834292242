import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { AiOutlineCreditCard } from 'react-icons/ai';
import Input from 'components/Input';

export const isValidCreditCardNumber = (cardNumber) => {
  if (
    // PayUni test card for staging environment
    process.env.REACT_APP_STAGE === 'staging' &&
    cardNumber === '4147631000000001'
  ) {
    return true;
  }

  // Check common card lengths
  const validLengths = [15, 16];
  if (!validLengths.includes(cardNumber.length)) {
    return false;
  }
  // Luhn algorithm
  let sum = 0;
  let shouldDouble = false;
  // Start from the last digit of the card number
  for (let i = cardNumber.length - 1; i >= 0; i--) {
    let digit = parseInt(cardNumber.charAt(i));

    if (shouldDouble) {
      digit *= 2;
      if (digit > 9) {
        digit -= 9;
      }
    }

    sum += digit;
    shouldDouble = !shouldDouble;
  }

  return sum % 10 === 0;
};

export const CreditCardNumberInput = ({ onChange, onBlur, isError, width }) => {
  const { t } = useTranslation();
  const [cardNumber, setCardNumber] = useState('');
  const [cardType, setCardType] = useState('Unknown');

  const formatCardNumber = (value) => {
    return value
      .replace(/\D/g, '')
      .replace(/(.{4})/g, '$1 ')
      .trim();
  };

  const determineCardType = (cardNumber) => {
    if (/^4/.test(cardNumber)) {
      return 'visa';
    } else if (/^5[1-5]/.test(cardNumber) || /^2[2-7]/.test(cardNumber)) {
      return 'masterCard';
    } else if (/^35(?:2[89]|[3-8][0-9])/.test(cardNumber)) {
      return 'jcb';
    } else {
      return 'Unknown';
    }
  };

  const handleChange = (event) => {
    const { value } = event.target;
    const formattedValue = formatCardNumber(value);
    const trimmedValue = value.replace(/\s+/g, '');
    setCardNumber(formattedValue);
    setCardType(determineCardType(trimmedValue));
    onChange(trimmedValue);
  };

  return (
    <Input
      label={t('creditCardNumber')}
      value={cardNumber}
      onChange={handleChange}
      onBlur={onBlur}
      error={isError}
      helperText={isError && t('creditCardNumberError')}
      placeholder={t('creditCardNumberPlaceholder')}
      prefix={
        cardType === 'Unknown' ? (
          <AiOutlineCreditCard size={18} />
        ) : (
          <img
            width={26}
            height={18}
            src={`/images/${cardType}.svg`}
            alt={cardType}
          />
        )
      }
      maxLength={19}
      type="text"
      width={width}
      required
    />
  );
};

export const isValidCreditCardDate = (date) => {
  if (date.length !== 4) {
    return false;
  }
  const month = parseInt(date.slice(0, 2));
  const year = parseInt(date.slice(2, 4));
  const currentYear = new Date().getFullYear().toString().slice(-2);
  const currentMonth = new Date().getMonth() + 1;
  return (
    month >= 1 &&
    month <= 12 &&
    year >= currentYear &&
    (year > currentYear || month >= currentMonth)
  );
};

export const CreditCardDateInput = ({ value, onChange, onBlur, isError }) => {
  const { t } = useTranslation();
  return (
    <Input
      label={t('expirationDate')}
      value={value}
      onChange={(e) => onChange(e.target.value)}
      onBlur={onBlur}
      error={isError}
      helperText={isError && t('expirationDateError')}
      placeholder={t('expirationDatePlaceholder')}
      type="text"
      fullWidth
      maxLength={4}
      required
    />
  );
};

export const isValidCreditCardCCV = (ccv) => {
  return [3, 4].includes(ccv.length);
};

export const CreditCardCCVInput = ({ value, onChange, onBlur, isError }) => {
  const { t } = useTranslation();
  return (
    <Input
      label={t('securityCode')}
      value={value}
      onChange={(e) => onChange(e.target.value)}
      onBlur={onBlur}
      error={isError}
      helperText={isError && t('securityCodeError')}
      placeholder={t('securityCode')}
      type="text"
      maxLength={4}
      fullWidth
      required
    />
  );
};

CreditCardNumberInput.propTypes = {
  onChange: PropTypes.func.isRequired,
  onBlur: PropTypes.func,
  isError: PropTypes.bool,
  width: PropTypes.number,
};

CreditCardDateInput.propTypes = {
  value: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
  onBlur: PropTypes.func,
  isError: PropTypes.bool,
};

CreditCardCCVInput.propTypes = {
  value: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
  onBlur: PropTypes.func,
  isError: PropTypes.bool,
};
