import { memo } from 'react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';
import Text from 'components/Text';
import { getUrlParams } from 'utils';
import { useLocation } from 'react-router-dom';

const Container = styled.div`
  border-radius: 16px;
  max-width: 736px;
  background: var(--genral-white-gray-800-bg, #fff);
  box-shadow: 0px 1px 15px 0px rgba(0, 0, 0, 0.04),
    0px 1px 2px -1px rgba(0, 0, 0, 0.02);
  padding: 40px;
  margin: auto;
  margin-top: 24px;
  button {
    margin-bottom: 24px;
  }
  @media (max-width: 375px) {
    padding: 40px 16px;
  }
`;

const ContentWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 24px;
  align-items: center;
`;

const InfoBlock = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  width: 100%;
  gap: 8px;
`;

const SuccessPage = () => {
  const { t } = useTranslation();
  const location = useLocation();
  const orderId = getUrlParams('orderId') || location.state.orderId || '-';
  const amount = getUrlParams('amount') || location.state.amount;

  return (
    <>
      <Container>
        <ContentWrapper>
          <Text
            font="Heading/H6/Heavy"
            color="SHADES_900"
            content={t('successTitle')}
            marginBottom={6}
          />
          <img
            width={161}
            height={140}
            src={'/images/success.svg'}
            alt="success"
          />
          <Text
            font="Body/16px_Regular"
            color="SHADES_600"
            content={t('successDescription')}
            marginTop={12}
          />
          <InfoBlock>
            <Text
              font="Body/14px_Heavy"
              color="SHADES_400"
              content={t('orderNumber')}
            />
            <Text
              font="Body/16px_Regular"
              color="SHADES_900"
              content={orderId}
            />
          </InfoBlock>
          <InfoBlock>
            <Text
              font="Body/14px_Heavy"
              color="SHADES_400"
              content={t('total')}
            />
            <Text
              font="Body/16px_Regular"
              color="SHADES_900"
              content={`NT$ ${new Intl.NumberFormat('en-US').format(amount)}`}
            />
          </InfoBlock>
        </ContentWrapper>
      </Container>
    </>
  );
};

export default memo(SuccessPage);
