import React, { memo } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { colors } from 'styles/theme';
import Text from 'components/Text';

const Field = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  max-width: ${({ fullWidth, width }) => (fullWidth ? '100%' : `${width}px`)};
  ${({ margin }) => margin && `margin: ${margin};`};
  ${({ marginTop }) => marginTop && `margin-top: ${marginTop}px;`};
  ${({ marginRight }) => marginRight && `margin-right:${marginRight}px;`};
  ${({ marginBottom }) => marginBottom && `margin-bottom: ${marginBottom}px;`};
  ${({ marginLeft }) => marginLeft && `margin-left: ${marginLeft}px;`};
`;

const FieldHead = styled.div`
  display: flex;
  justify-content: space-between;
  font-size: 12px;
  line-height: 15.6px;
  letter-spacing: 0.2px;
  color: ${colors.SHADES_400};

  label {
    margin-bottom: 8px;
    color: ${colors.SHADES_900};
    font-weight: 700;
    ${({ required }) => {
      if (required) {
        return `
          &::after {
            content: '*';
            color: ${colors.SYSTEM_ERROR_600};
          }
        `;
      }
    }}
  }

  span {
    margin-bottom: 8px;
  }
`;

const FieldBody = styled.div``;

const FieldHelper = styled.p`
  display: flex;
  align-items: center;
  gap: 4px;
  margin-top: 8px;
  margin-bottom: 0;
`;

const FieldBase = ({
  width = 320,
  margin,
  marginTop,
  marginRight,
  marginBottom,
  marginLeft,
  fullWidth,
  required,
  name,
  label,
  optionalLabel,
  helperText,
  children,
  error,
}) => {
  return (
    <Field
      width={width}
      fullWidth={fullWidth}
      margin={margin}
      marginTop={marginTop}
      marginRight={marginRight}
      marginBottom={marginBottom}
      marginLeft={marginLeft}
    >
      {(label || optionalLabel) && (
        <FieldHead required={required}>
          {label && <label htmlFor={name}>{label}</label>}
          {optionalLabel && <span>{optionalLabel}</span>}
        </FieldHead>
      )}
      <FieldBody>{children}</FieldBody>
      {helperText && (
        <FieldHelper error={error}>
          <Text
            font="Body/12px_Regular"
            color={error ? 'SYSTEM_ERROR_600' : 'SHADES_400'}
            content={helperText}
          />
        </FieldHelper>
      )}
    </Field>
  );
};

FieldBase.propTypes = {
  width: PropTypes.number,
  margin: PropTypes.string,
  marginTop: PropTypes.number,
  marginRight: PropTypes.number,
  marginBottom: PropTypes.number,
  marginLeft: PropTypes.number,
  fullWidth: PropTypes.bool,
  required: PropTypes.bool,
  name: PropTypes.string,
  label: PropTypes.string,
  optionalLabel: PropTypes.string,
  helperText: PropTypes.oneOfType([
    PropTypes.bool,
    PropTypes.string,
    PropTypes.node,
  ]),
  children: PropTypes.node,
  error: PropTypes.bool,
};

export default memo(FieldBase);
