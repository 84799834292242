import React, { memo } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

const StyledLabel = styled.label`
  display: flex;
  color: #5cc2e7;
  align-items: flex-start;
  user-select: none;
  cursor: pointer;
  input[type='radio'] {
    color: #5cc2e7;
    align-self: center;
    height: 16px;
    width: 16px;
    margin: 0 8px 0 0;
  }

  &:not(:last-child) {
    margin-bottom: ${({ marginBottom }) => `${marginBottom}px`};
  }
`;

const InvoiceCheckbox = ({
  onChange,
  checked,
  disabled,
  children,
  marginBottom = 24,
  fontSize = 16,
}) => (
  <StyledLabel marginBottom={marginBottom} fontSize={fontSize}>
    <input
      onChange={(e) => onChange(e.target.checked)}
      checked={checked}
      disabled={disabled}
      type="radio"
    />
    {children}
  </StyledLabel>
);

InvoiceCheckbox.propTypes = {
  onChange: PropTypes.func,
  checked: PropTypes.bool,
  disabled: PropTypes.bool,
  children: PropTypes.node,
  marginBottom: PropTypes.number,
  fontSize: PropTypes.number,
};

export default memo(InvoiceCheckbox);
