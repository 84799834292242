import { css } from 'styled-components';

export const colors = {
  PRIMARY_100: '#E1EFFE',
  PRIMARY_200: '#C3DDFD',
  PRIMARY_300: '#A4CAFE',
  PRIMARY_400: '#76A9FA',
  PRIMARY_500: '#3F83F8',
  PRIMARY_600: '#1C64F2',
  PRIMARY_700: '#1A56DB',
  PRIMARY_800: '#1E429F',
  PRIMARY_900: '#233876',
  SECONDARY_100: '#fef8e0',
  SECONDARY_200: '#fef0c2',
  SECONDARY_300: '#fce5a4',
  SECONDARY_400: '#fad98c',
  SECONDARY_500: '#f7c767',
  SECONDARY_600: '#d4a24b',
  SECONDARY_700: '#b17f33',
  SECONDARY_800: '#8f5f20',
  SECONDARY_900: '#764813',
  SHADES_000: '#ffffff',
  SHADES_50: '#f8fafc',
  SHADES_100: '#f1f5f9',
  SHADES_200: '#e2e8f0',
  SHADES_300: '#cbd5e1',
  SHADES_400: '#94a3b8',
  SHADES_500: '#64748b',
  SHADES_600: '#475569',
  SHADES_700: '#334155',
  SHADES_800: '#1e293b',
  SHADES_900: '#0f172a',
  SYSTEM_ERROR_100: '#FDE8E8',
  SYSTEM_ERROR_200: '#FBD5D5',
  SYSTEM_ERROR_300: '#F8B4B4',
  SYSTEM_ERROR_400: '#F98080',
  SYSTEM_ERROR_500: '#F05252',
  SYSTEM_ERROR_600: '#E02424',
  SYSTEM_ERROR_700: '#C81E1E',
  SYSTEM_ERROR_800: '#9B1C1C',
  SYSTEM_ERROR_900: '#771D1D',
  SYSTEM_WARNING_100: '#fefbcc',
  SYSTEM_WARNING_200: '#fef799',
  SYSTEM_WARNING_300: '#fdf166',
  SYSTEM_WARNING_400: '#fbeb40',
  SYSTEM_WARNING_500: '#f9e102',
  SYSTEM_WARNING_600: '#d6bf01',
  SYSTEM_WARNING_700: '#b39e01',
  SYSTEM_WARNING_800: '#907e00',
  SYSTEM_WARNING_900: '#776700',
  SYSTEM_SUCCESS_100: '#ecfdd7',
  SYSTEM_SUCCESS_200: '#d3fbb0',
  SYSTEM_SUCCESS_300: '#b3f587',
  SYSTEM_SUCCESS_400: '#93ec67',
  SYSTEM_SUCCESS_500: '#64e038',
  SYSTEM_SUCCESS_600: '#46c028',
  SYSTEM_SUCCESS_700: '#2ca11c',
  SYSTEM_SUCCESS_800: '#178111',
  SYSTEM_SUCCESS_900: '#0a6b0c',
  SYSTEM_INFO_100: '#d2e5fe',
  SYSTEM_INFO_300: '#78aafa',
  SYSTEM_INFO_200: '#a6cafd',
  SYSTEM_INFO_500: '#2162ef',
  SYSTEM_INFO_400: '#568df5',
  SYSTEM_INFO_600: '#184bcd',
  SYSTEM_INFO_700: '#1037ac',
  SYSTEM_INFO_900: '#061a72',
  SYSTEM_INFO_800: '#0a268a',
};

export const fonts = {
  'EN/Heading/Title/Heavy': css`
    font-family: Inter;
    font-size: 69px;
    font-weight: bold;
    line-height: 89.7px;
  `,
  'EN/Heading/Title/Medium': css`
    font-family: Inter;
    font-size: 69px;
    font-weight: bold;
    line-height: 89.7px;
  `,
  'Heading/Title/Heavy': css`
    font-family: 'Noto Sans TC', sans-serif;
    font-size: 69px;
    font-weight: bold;
    line-height: 89.7px;
    letter-spacing: 1.4px;
  `,
  'Heading/Title/Medium': css`
    font-family: 'Noto Sans TC', sans-serif;
    font-size: 69px;
    font-weight: 500;
    line-height: 89.7px;
    letter-spacing: 1.4px;
  `,
  'EN/Heading/Title/Regular': css`
    font-family: Inter;
    font-size: 69px;
    line-height: 89.7px;
  `,
  'Heading/Title/Regular': css`
    font-family: 'Noto Sans TC', sans-serif;
    font-size: 69px;
    line-height: 89.7px;
    letter-spacing: 1.4px;
  `,
  'EN/Heading/H1/Heavy': css`
    font-family: Inter;
    font-size: 57px;
    font-weight: bold;
    line-height: 74.1px;
  `,
  'Heading/H1/Heavy': css`
    font-family: 'Noto Sans TC', sans-serif;
    font-size: 57px;
    font-weight: bold;
    line-height: 74.1px;
    letter-spacing: 1.1px;
  `,
  'EN/Heading/H1/Medium': css`
    font-family: Inter;
    font-size: 57px;
    font-weight: 500;
    line-height: 74.1px;
  `,
  'Heading/H1/Medium': css`
    font-family: 'Noto Sans TC', sans-serif;
    font-size: 57px;
    font-weight: 500;
    line-height: 74.1px;
    letter-spacing: 1.1px;
  `,
  'EN/Heading/H1/Regular': css`
    font-family: Inter;
    font-size: 57px;
    line-height: 74.1px;
  `,
  'Heading/H1/Regular': css`
    font-family: 'Noto Sans TC', sans-serif;
    font-size: 57px;
    line-height: 74.1px;
    letter-spacing: 1.1px;
  `,
  'EN/Heading/H2/Heavy': css`
    font-family: Inter;
    font-size: 48px;
    font-weight: bold;
    line-height: 62.4px;
  `,
  'Heading/H2/Heavy': css`
    font-family: 'Noto Sans TC', sans-serif;
    font-size: 48px;
    font-weight: bold;
    line-height: 62.4px;
    letter-spacing: 1px;
  `,
  'EN/Heading/H2/Medium': css`
    font-family: Inter;
    font-size: 48px;
    font-weight: 500;
    line-height: 62.4px;
  `,
  'Heading/H2/Medium': css`
    font-family: 'Noto Sans TC', sans-serif;
    font-size: 48px;
    font-weight: 500;
    line-height: 62.4px;
    letter-spacing: 1px;
  `,
  'EN/Heading/H2/Regular': css`
    font-family: Inter;
    font-size: 48px;
    line-height: 62.4px;
  `,
  'Heading/H2/Regular': css`
    font-family: 'Noto Sans TC', sans-serif;
    font-size: 48px;
    line-height: 62.4px;
    letter-spacing: 1px;
  `,
  'EN/Heading/H3/Heavy': css`
    font-family: Inter;
    font-size: 40px;
    font-weight: bold;
    line-height: 52px;
  `,
  'Heading/H3/Heavy': css`
    font-family: 'Noto Sans TC', sans-serif;
    font-size: 40px;
    font-weight: bold;
    line-height: 52px;
    letter-spacing: 0.8px;
  `,
  'EN/Heading/H3/Medium': css`
    font-family: Inter;
    font-size: 40px;
    font-weight: 500;
    line-height: 52px;
  `,
  'Heading/H3/Medium': css`
    font-family: 'Noto Sans TC', sans-serif;
    font-size: 40px;
    font-weight: 500;
    line-height: 52px;
    letter-spacing: 0.8px;
  `,
  'EN/Heading/H3/Regular': css`
    font-family: Inter;
    font-size: 40px;
    line-height: 52px;
  `,
  'Heading/H3/Regular': css`
    font-family: 'Noto Sans TC', sans-serif;
    font-size: 40px;
    line-height: 52px;
    letter-spacing: 0.8px;
  `,
  'EN/Heading/H4/Heavy': css`
    font-family: Inter;
    font-size: 33px;
    font-weight: bold;
    line-height: 42.9px;
  `,
  'Heading/H4/Heavy': css`
    font-family: 'Noto Sans TC', sans-serif;
    font-size: 33px;
    font-weight: bold;
    line-height: 42.9px;
    letter-spacing: 0.7px;
  `,
  'EN/Heading/H4/Medium': css`
    font-family: Inter;
    font-size: 33px;
    font-weight: 500;
    line-height: 42.9px;
  `,
  'Heading/H4/Medium': css`
    font-family: 'Noto Sans TC', sans-serif;
    font-size: 33px;
    font-weight: 500;
    line-height: 42.9px;
    letter-spacing: 0.7px;
  `,
  'EN/Heading/H4/Regular': css`
    font-family: Inter;
    font-size: 33px;
    line-height: 42.9px;
  `,
  'Heading/H4/Regular': css`
    font-family: 'Noto Sans TC', sans-serif;
    font-size: 33px;
    line-height: 42.9px;
    letter-spacing: 0.7px;
  `,
  'EN/Heading/H5/Heavy': css`
    font-family: Inter;
    font-size: 28px;
    font-weight: bold;
    line-height: 36.4px;
  `,
  'Heading/H5/Heavy': css`
    font-family: 'Noto Sans TC', sans-serif;
    font-size: 28px;
    font-weight: bold;
    line-height: 36.4px;
    letter-spacing: 0.6px;
  `,
  'EN/Heading/H5/Medium': css`
    font-family: Inter;
    font-size: 28px;
    font-weight: 500;
    line-height: 36.4px;
  `,
  'Heading/H5/Medium': css`
    font-family: 'Noto Sans TC', sans-serif;
    font-size: 28px;
    font-weight: 500;
    line-height: 36.4px;
    letter-spacing: 0.6px;
  `,
  'EN/Heading/H5/Regular': css`
    font-family: Inter;
    font-size: 28px;
    line-height: 36.4px;
  `,
  'Heading/H5/Regular': css`
    font-family: 'Noto Sans TC', sans-serif;
    font-size: 28px;
    line-height: 36.4px;
    letter-spacing: 0.6px;
  `,
  'EN/Heading/H6/Heavy': css`
    font-family: Inter;
    font-size: 23px;
    font-weight: bold;
    line-height: 29.9px;
  `,
  'Heading/H6/Heavy': css`
    font-family: 'Noto Sans TC', sans-serif;
    font-size: 23px;
    font-weight: bold;
    line-height: 29.9px;
    letter-spacing: 0.5px;
  `,
  'EN/Heading/H6/Medium': css`
    font-family: Inter;
    font-size: 23px;
    font-weight: 500;
    line-height: 29.9px;
  `,
  'Heading/H6/Medium': css`
    font-family: 'Noto Sans TC', sans-serif;
    font-size: 23px;
    font-weight: 500;
    line-height: 29.9px;
    letter-spacing: 0.5px;
  `,
  'EN/Heading/H6/Regular': css`
    font-family: Inter;
    font-size: 23px;
    line-height: 29.9px;
  `,
  'Heading/H6/Regular': css`
    font-family: 'Noto Sans TC', sans-serif;
    font-size: 23px;
    line-height: 29.9px;
    letter-spacing: 0.5px;
  `,
  'EN/Body/19/Heavy': css`
    font-family: Inter;
    font-size: 19px;
    font-weight: bold;
    line-height: 28px;
  `,
  'EN/Heading/Large/Heavy': css`
    font-family: Inter;
    font-size: 19px;
    font-weight: bold;
    line-height: 24.7px;
  `,
  'Body/19px_Heavy': css`
    font-family: 'Noto Sans TC', sans-serif;
    font-size: 19px;
    font-weight: bold;
    line-height: 28px;
    letter-spacing: 0.4px;
  `,
  'Heading/Large/Heavy': css`
    font-family: 'Noto Sans TC', sans-serif;
    font-size: 19px;
    font-weight: bold;
    line-height: 24.7px;
    letter-spacing: 0.4px;
  `,
  'EN/Body/19/Medium': css`
    font-family: Inter;
    font-size: 19px;
    font-weight: 500;
    line-height: 28px;
  `,
  'EN/Heading/Large/Medium': css`
    font-family: Inter;
    font-size: 19px;
    font-weight: 500;
    line-height: 24.7px;
  `,
  'Body/19px_Medium': css`
    font-family: 'Noto Sans TC', sans-serif;
    font-size: 19px;
    font-weight: 500;
    line-height: 28px;
    letter-spacing: 0.4px;
  `,
  'Heading/Large/Medium': css`
    font-family: 'Noto Sans TC', sans-serif;
    font-size: 19px;
    font-weight: 500;
    line-height: 24.7px;
    letter-spacing: 0.4px;
  `,
  'EN/Body/19/Regular': css`
    font-family: Inter;
    font-size: 19px;
    line-height: 28px;
  `,
  'EN/Heading/Large/Regular': css`
    font-family: Inter;
    font-size: 19px;
    line-height: 24.7px;
  `,
  'Body/19px_Regular': css`
    font-family: 'Noto Sans TC', sans-serif;
    font-size: 19px;
    line-height: 28px;
    letter-spacing: 0.4px;
  `,
  'Heading/Large/Regular': css`
    font-family: 'Noto Sans TC', sans-serif;
    font-size: 19px;
    line-height: 24.7px;
    letter-spacing: 0.4px;
  `,
  'EN/Body/16/Heavy': css`
    font-family: Inter;
    font-size: 16px;
    font-weight: bold;
    line-height: 24px;
  `,
  'EN/Heading/Medium/Heavy': css`
    font-family: Inter;
    font-size: 16px;
    font-weight: bold;
    line-height: 20.8px;
  `,
  'Body/16px_Heavy': css`
    font-family: 'Noto Sans TC', sans-serif;
    font-size: 16px;
    font-weight: bold;
    line-height: 24px;
    letter-spacing: 0.3px;
  `,
  'Heading/Medium/Heavy': css`
    font-family: 'Noto Sans TC', sans-serif;
    font-size: 16px;
    font-weight: bold;
    line-height: 20.8px;
    letter-spacing: 0.3px;
  `,
  'EN/Body/16/Medium': css`
    font-family: Inter;
    font-size: 16px;
    font-weight: 500;
    line-height: 24px;
  `,
  'EN/Heading/Medium/Medium': css`
    font-family: Inter;
    font-size: 16px;
    font-weight: 500;
    line-height: 20.8px;
  `,
  'Body/16px_Medium': css`
    font-family: 'Noto Sans TC', sans-serif;
    font-size: 16px;
    font-weight: 500;
    line-height: 24px;
    letter-spacing: 0.3px;
  `,
  'Heading/Medium/Medium': css`
    font-family: 'Noto Sans TC', sans-serif;
    font-size: 16px;
    font-weight: 500;
    line-height: 20.8px;
    letter-spacing: 0.3px;
  `,
  'EN/Body/16/Regular': css`
    font-family: Inter;
    font-size: 16px;
    line-height: 24px;
  `,
  'EN/Heading/Medium/Regular': css`
    font-family: Inter;
    font-size: 16px;
    line-height: 20.8px;
  `,
  'Body/16px_Regular': css`
    font-family: 'Noto Sans TC', sans-serif;
    font-size: 16px;
    line-height: 24px;
    letter-spacing: 0.3px;
  `,
  'Heading/Medium/Regular': css`
    font-family: 'Noto Sans TC', sans-serif;
    font-size: 16px;
    line-height: 20.8px;
    letter-spacing: 0.3px;
  `,
  'Body/14px_Heavy': css`
    font-family: 'Noto Sans TC', sans-serif;
    font-size: 14px;
    font-weight: bold;
    line-height: 24px;
    letter-spacing: 0.3px;
  `,
  'Body/14px_Medium': css`
    font-family: 'Noto Sans TC', sans-serif;
    font-size: 14px;
    font-weight: 500;
    line-height: 24px;
    letter-spacing: 0.3px;
  `,
  'Body/14px_Regular': css`
    font-family: 'Noto Sans TC', sans-serif;
    font-size: 14px;
    line-height: 24px;
    letter-spacing: 0.3px;
  `,
  'EN/Body/13/Heavy': css`
    font-family: Inter;
    font-size: 13px;
    font-weight: bold;
    line-height: 24px;
  `,
  'EN/Heading/Small/Heavy': css`
    font-family: Inter;
    font-size: 13px;
    font-weight: bold;
    line-height: 16.9px;
  `,
  'Heading/Small/Heavy': css`
    font-family: 'Noto Sans TC', sans-serif;
    font-size: 13px;
    font-weight: bold;
    line-height: 16.9px;
    letter-spacing: 0.3px;
  `,
  'EN/Body/13/Medium': css`
    font-family: Inter;
    font-size: 13px;
    font-weight: 500;
    line-height: 24px;
  `,
  'EN/Heading/Small/Medium': css`
    font-family: Inter;
    font-size: 13px;
    font-weight: 500;
    line-height: 16.9px;
  `,
  'Heading/Small/Medium': css`
    font-family: 'Noto Sans TC', sans-serif;
    font-size: 13px;
    font-weight: 500;
    line-height: 16.9px;
    letter-spacing: 0.3px;
  `,
  'EN/Body/13/Regular': css`
    font-family: Inter;
    font-size: 13px;
    line-height: 24px;
  `,
  'EN/Heading/Small/Regular': css`
    font-family: Inter;
    font-size: 13px;
    line-height: 16.9px;
  `,
  'Heading/Small/Regular': css`
    font-family: 'Noto Sans TC', sans-serif;
    font-size: 13px;
    line-height: 16.9px;
    letter-spacing: 0.3px;
  `,
  'Body/12px_Heavy': css`
    font-family: 'Noto Sans TC', sans-serif;
    font-size: 12px;
    font-weight: bold;
    line-height: 20px;
    letter-spacing: 0.2px;
  `,
  'Heading/X-Small/Heavy': css`
    font-family: 'Noto Sans TC', sans-serif;
    font-size: 12px;
    font-weight: bold;
    line-height: 15.6px;
    letter-spacing: 0.2px;
  `,
  'Body/12px_Medium': css`
    font-family: 'Noto Sans TC', sans-serif;
    font-size: 12px;
    font-weight: 500;
    line-height: 20px;
    letter-spacing: 0.2px;
  `,
  'Heading/X-Small/Medium': css`
    font-family: 'Noto Sans TC', sans-serif;
    font-size: 12px;
    font-weight: 500;
    line-height: 15.6px;
    letter-spacing: 0.2px;
  `,
  'Body/12px_Regular': css`
    font-family: 'Noto Sans TC', sans-serif;
    font-size: 12px;
    line-height: 16px;
    letter-spacing: 0.2px;
  `,
  'Heading/X-Small/Regular': css`
    font-family: 'Noto Sans TC', sans-serif;
    font-size: 12px;
    line-height: 15.6px;
    letter-spacing: 0.2px;
  `,
  'EN/Body/11/Heavy': css`
    font-family: Inter;
    font-size: 11px;
    font-weight: bold;
    line-height: 20px;
  `,
  'EN/Body/11/Medium': css`
    font-family: Inter;
    font-size: 11px;
    font-weight: 500;
    line-height: 20px;
  `,
  'EN/Body/11/Regular': css`
    font-family: Inter;
    font-size: 11px;
    line-height: 20px;
  `,
  'EN/Body/9/Heavy': css`
    font-family: Inter;
    font-size: 9px;
    font-weight: bold;
    line-height: 16px;
  `,
  'Body/9px_Heavy': css`
    font-family: 'Noto Sans TC', sans-serif;
    font-size: 9px;
    font-weight: bold;
    line-height: 16px;
    letter-spacing: 0.2px;
  `,
  'Heading/Sub/Heavy': css`
    font-family: 'Noto Sans TC', sans-serif;
    font-size: 9px;
    font-weight: bold;
    line-height: 11.7px;
    letter-spacing: 0.2px;
  `,
  'EN/Body/9/Medium': css`
    font-family: Inter;
    font-size: 9px;
    font-weight: 500;
    line-height: 16px;
  `,
  'Body/9px_Medium': css`
    font-family: 'Noto Sans TC', sans-serif;
    font-size: 9px;
    font-weight: 500;
    line-height: 16px;
    letter-spacing: 0.2px;
  `,
  'Heading/Sub/Medium': css`
    font-family: 'Noto Sans TC', sans-serif;
    font-size: 9px;
    font-weight: 500;
    line-height: 11.7px;
    letter-spacing: 0.2px;
  `,
  'EN/Body/9/Regular': css`
    font-family: Inter;
    font-size: 9px;
    line-height: 16px;
  `,
  'Body/9px_Regular': css`
    font-family: 'Noto Sans TC', sans-serif;
    font-size: 9px;
    line-height: 16px;
    letter-spacing: 0.2px;
  `,
  'Heading/Sub/Regular': css`
    font-family: 'Noto Sans TC', sans-serif;
    font-size: 9px;
    line-height: 11.7px;
    letter-spacing: 0.2px;
  `,
};
