import React, { memo } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import PulseLoader from 'react-spinners/PulseLoader';
import { fonts, colors } from 'styles/theme';

const StyledButton = styled.button`
  display: inline-flex;
  align-items: center;
  justify-content: center;
  width: ${({ fullWidth }) => (fullWidth ? '100%' : 'auto')};
  font-size: ${({ fontSize }) => `${fontSize || 16}px`};
  border: none;
  border-radius: 8px;
  letter-spacing: 0.02em;
  transition: 0.3s;
  outline: none;
  cursor: pointer;
  min-height: ${({ size }) => {
    switch (size) {
      case 'large':
        return '42px';
      case 'small':
        return '28px';
      default:
        return '38px';
    }
  }};
  padding: ${({ size }) => {
    switch (size) {
      case 'large':
        return '12px 24px';
      case 'small':
        return '2px 12px;';
      default:
        return '8px 16px;';
    }
  }};

  ${({ nowrap }) => nowrap && `white-space: nowrap;`};

  ${({ font }) => font && fonts[font]};

  ${({ variant, color, disabled }) => {
    if (variant === 'filled') {
      const setting = {};
      if (color === 'primary') {
        setting.color = colors.SHADES_000;
        setting.bgColor = colors.PRIMARY_600;
        setting.hoverColor = colors.PRIMARY_400;
      }
      if (color === 'secondary') {
        setting.color = colors.SHADES_500;
        setting.bgColor = colors.SHADES_100;
        setting.hoverColor = colors.SHADES_100;
      }
      if (color === 'error') {
        setting.color = colors.SHADES_000;
        setting.bgColor = colors.SYSTEM_ERROR_600;
        setting.hoverColor = colors.SHADES_100;
      }
      return `
        color: ${setting.color};
        background-color: ${setting.bgColor};        ${
        disabled &&
        `
            &,
            &:hover {
              cursor: not-allowed;
              color: ${colors.SHADES_300};
              background-color: ${setting.hoverColor};
            }
          `
      }
      `;
    }

    if (variant === 'outline') {
      const setting = {};
      if (color === 'primary') {
        setting.color = colors.PRIMARY_400;
        setting.borderColor = colors.PRIMARY_400;
      }
      if (color === 'secondary') {
        setting.color = colors.SHADES_500;
        setting.borderColor = colors.SHADES_400;
      }
      if (color === 'error') {
        setting.color = colors.SYSTEM_ERROR_400;
        setting.borderColor = colors.SYSTEM_ERROR_400;
      }
      return `
        color: ${setting.color};
        border: 1px solid ${setting.borderColor};
        background-color: ${colors.SHADES_000};
        transition: 0.3s;
        ${
          disabled &&
          `
            &,
            &:hover {
              cursor: not-allowed;
              color: ${colors.SHADES_300};
              border-color:  ${colors.SHADES_200};
              background-color: ${colors.SHADES_000};
            }
          `
        }
      `;
    }

    if (variant === 'ghost') {
      const setting = {};
      if (color === 'primary') {
        setting.color = colors.PRIMARY_500;
      }
      if (color === 'secondary') {
        setting.color = colors.SHADES_400;
      }
      if (color === 'error') {
        setting.color = colors.SYSTEM_ERROR_600;
      }
      return `
        color: ${setting.color};
        border: none;
        background-color: transparent;
        ${
          disabled &&
          `
            &,
            &:hover {
              cursor: not-allowed;
              color: ${colors.SHADES_300};
            }
          `
        }
      `;
    }
  }}
`;

const Button = ({
  fullWidth,
  color = 'primary',
  variant = 'filled',
  size,
  font,
  nowrap,
  isLoading,
  disabled,
  onClick = () => {},
  children,
  ...restProps
}) => (
  <StyledButton
    color={color}
    variant={variant}
    font={font}
    size={size}
    nowrap={nowrap}
    onClick={onClick}
    fullWidth={fullWidth}
    disabled={disabled || isLoading}
    {...restProps}
  >
    {isLoading ? (
      <PulseLoader
        size={size === 'small' ? 4 : 8}
        color={color === 'secondary' ? colors.PRIMARY_400 : colors.SHADES_000}
      />
    ) : (
      children
    )}
  </StyledButton>
);

Button.propTypes = {
  fullWidth: PropTypes.bool,
  color: PropTypes.string,
  variant: PropTypes.string,
  font: PropTypes.string,
  size: PropTypes.string,
  nowrap: PropTypes.bool,
  isLoading: PropTypes.bool,
  disabled: PropTypes.bool,
  onClick: PropTypes.func,
  children: PropTypes.node,
};

export default memo(Button);
