import { memo } from 'react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';
import Text from 'components/Text';

const FooterText = styled.div`
  text-align: center;
  margin-bottom: 40px;
  @media (max-width: 768px) {
    text-align: left;
    padding-left: 32px;
  }
`;

const Footer = () => {
  const { t } = useTranslation();
  return (
    <FooterText>
      <Text
        font="Heading/Medium/Regular"
        color="SHADES_500"
        content={t('footer')}
      />
    </FooterText>
  );
};

export default memo(Footer);
