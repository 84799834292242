export const responseHandler = (res) => {
  if (res.status >= 400) {
    throw res.json();
  }
  return res.json();
};

export const test = () => true;

export const getUrlParams = (key) => {
  const params = new URLSearchParams(window.location.search);
  return params.get(key);
};
