import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import { getUrlParams } from 'utils';
import jp from './locales/jp.json';
import en from './locales/en.json';
import zh_TW from './locales/zh_TW.json';

const resources = {
  en: {
    translation: en,
  },
  zh_TW: {
    translation: zh_TW,
  },
  jp: {
    translation: jp,
  },
};

i18n.use(initReactI18next).init({
  resources,
  fallbackLng: 'zh_TW',
  lng: getUrlParams('locale') || 'zh_TW',
  interpolation: {
    escapeValue: false,
  },
});

export default i18n;
