import { memo, useRef, useState, useEffect } from 'react';
import styled from 'styled-components';
import Logo from 'images/Logo';
import { colors } from 'styles/theme';
import { useTranslation } from 'react-i18next';
import { RiGlobalLine } from 'react-icons/ri';
import Text from 'components/Text';

const HeaderWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  background-color: ${colors.SHADES_000};
`;

const ContentWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  max-width: 1440px;
  padding: 14px 80px 14px 80px;
`;

const LanguageWrapper = styled.div`
  position: relative;
  display: flex;
  align-items: center;
  gap: 4px;
  cursor: pointer;
`;

const LanguageOptions = styled.div`
  position: absolute;
  background-color: #fff;
  box-shadow: 0px 24px 32px rgba(0, 0, 0, 0.04),
    0px 16px 24px rgba(0, 0, 0, 0.04), 0px 4px 8px rgba(0, 0, 0, 0.04),
    0px 0px 1px rgba(0, 0, 0, 0.04);
  border-radius: 8px;
  width: 120px;
  top: calc(100% + 8px);
  right: 0;
  display: flex;
  flex-direction: column;
  gap: 16px;
  padding: 16px;
  > span {
    &:hover {
      color: ${colors.PRIMARY_500};
    }
  }
`;

const Header = () => {
  const ref = useRef(null);
  const { t, i18n } = useTranslation();
  const [open, setOpen] = useState(false);

  const handleLanguageChange = (language) => {
    setOpen(false);
    i18n.changeLanguage(language);
  };

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (ref.current && !ref.current.contains(event.target)) {
        setOpen(false);
      }
    };
    document.addEventListener('click', handleClickOutside);
    return () => {
      document.removeEventListener('click', handleClickOutside);
    };
  }, [ref]);

  useEffect(() => {
    const urlParams = new URLSearchParams(window.location.search);
    const lang = urlParams.get('lang');
    if (['zh_TW', 'en', 'jp'].includes(lang)) {
      i18n.changeLanguage(lang);
    }
  }, [i18n]);

  return (
    <HeaderWrapper>
      <ContentWrapper>
        <Logo />
        <LanguageWrapper onClick={() => setOpen(true)} ref={ref}>
          <RiGlobalLine color={colors.SHADES_400} size={20} />
          <Text
            font="Body/16px_Regular"
            color="SHADES_400"
            content={t(i18n.language)}
          />
          {open && (
            <LanguageOptions onClick={(e) => e.stopPropagation()}>
              <Text
                font="Body/16px_Regular"
                color="SHADES_900"
                content={t('zh_TW')}
                onClick={() => handleLanguageChange('zh_TW')}
              />
              <Text
                font="Body/16px_Regular"
                color="SHADES_900"
                content={t('en')}
                onClick={() => handleLanguageChange('en')}
              />
              <Text
                font="Body/16px_Regular"
                color="SHADES_900"
                content={t('jp')}
                onClick={() => handleLanguageChange('jp')}
              />
            </LanguageOptions>
          )}
        </LanguageWrapper>
      </ContentWrapper>
    </HeaderWrapper>
  );
};

export default memo(Header);
